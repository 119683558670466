











import {Component, Vue} from 'vue-property-decorator';
import UserHead from "@/components/user-head.vue";
@Component({
    components: {UserHead}
})
export default class Header extends Vue {

}
