























import { Component, Vue } from 'vue-property-decorator';
import DigitsGuessNumber from "@/components/digits-guess-number.vue";
import WindChimeWork from "@/components/WindChime-Work.vue";
import SimpleLayout from "@/views/layouts/SimpleLayout.vue";
import {ApiGetGameLog} from "@/request-apis/keqing-helper/game/get-log";

@Component({
    components:{
        SimpleLayout,
        DigitsGuessNumber,
        WindChimeWork,
    }
})
export default class ViewLog extends Vue {
    private code:number = -1;
    private msg:string = "";
    private gameId:string = "";
    private jsonData:any;

    public created() {
        document.title = "浏览日志 - 刻晴小助手";
    }

    public mounted() {
        let game = this.$route.params.gameId;
        let target = this.$route.params.targetId;
        let round = this.$route.params.roundId;
        this.code = -1;
        new ApiGetGameLog({game, target, round}).run().then(res => {
            this.onApiFinished(res);
        })
    }

    private onApiFinished(json:any) {
        this.code = json.code;
        this.msg = json.msg;
        if(this.code == 0) {
            this.jsonData = json;
            this.gameId = this.jsonData.game;
        }
    }
}
