






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    private copyRight:string = "";
    public mounted() {
        this.setCopyRight();
    }

    private setCopyRight() {
        let start = 2015;
        let end = new Date().getFullYear();
        this.copyRight = `©${start} - ${end}`;
    }
}
