

















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Utils} from "@/utils/utils";

@Component
export default class DigitsGuessNumber extends Vue {
    @Prop() jsonData!:any;
    private startTimeNumber:number = 0;
    private startTime:string = "";
    private playerDict:{[qq:number]:string} = {};

    public mounted() {
        document.title = "对位猜数日志 - 刻晴小助手";
        this.startTimeNumber = this.jsonData.data.info.startTime;
        this.startTime = this.timespan2Str(this.jsonData.data.info.startTime);
        for(let item of this.jsonData.data.players) {
            this.playerDict[item.id] = item.name;
        }
    }

    private getPlayerName(qq:number):string {
        return this.playerDict[qq];
    }

    private timespan2Str(time:number) {
        return new Date(time).Format("yyyy-MM-dd HH:mm:ss");
    }

    private gameTime(time:number) {
        let ms = (time - this.startTimeNumber) / 1000;
        return Utils.second2hmsStr(ms);
    }

    private getStat(qq:number,key:string,def?:any) {
        if(qq in this.jsonData.data.stat && key in this.jsonData.data.stat[qq]) {
            return this.jsonData.data.stat[qq][key];
        } else {
            return def;
        }
    }

}
