

















import {Component, Vue} from "vue-property-decorator";
import UserModule from "@/components/modules/UserModule.vue";
import KeqingHeader from "@/components/header.vue";
import KeqingFooter from "@/components/footer.vue";

@Component({
    components:{
        UserModule,
        KeqingHeader,
        KeqingFooter
    }
})
export default class SimpleLayout extends Vue {

}
